import type { NuxtAxiosInstance } from '@nuxtjs/axios'
import type { Context } from '@nuxt/types'
import { ContactPathParams, Contact, PhoneNumberValidation } from '@/services/types/Contact'

interface IContactService {
  $axios: NuxtAxiosInstance
  checkPhoneNumber(tenantId: string, phoneNumberId: string, phoneNumber: string): Promise<PhoneNumberValidation>
  save(tenantId: string, phoneNumberId: string, contact: Contact): Promise<Contact>
  clone({ tenantId, phoneNumberId, contactId }: ContactPathParams, destinationPhoneNumberId: string): Promise<Contact>
  unsubscribeContact({ tenantId, phoneNumberId, contactId }: ContactPathParams, blocked: boolean, isBlacklisted: boolean): Promise<Contact>
}

export default class ContactService implements IContactService {
  $axios: NuxtAxiosInstance

  constructor (context: Context) {
    this.$axios = context.$axios
  }

  checkPhoneNumber (tenantId: string, phoneNumberId: string, phoneNumber: string) {
    return this.$axios.$get(`/api/contacts/${tenantId}/${phoneNumberId}/check-number`, {
      params: {
        phone: phoneNumber,
        'create-thread': false,
        'check-carrier': true
      }
    })
  }

  save (tenantId: string, phoneNumberId: string, contact: Contact) {
    if (contact.id) {
      const contact2Send = { ...contact, blocked: !!contact.blocked }
      return this.$axios
        .$patch(`/api/contacts/${tenantId}/${phoneNumberId}/${contact.id}`, contact2Send)
    } else {
      return this.$axios
        .$post(`/api/contacts/${tenantId}/${phoneNumberId}`, contact)
    }
  }

  clone ({ tenantId, phoneNumberId, contactId }: ContactPathParams, destinationPhoneNumberId: string) {
    return this.$axios.$post(`/api/contacts/${tenantId}/${phoneNumberId}/${contactId}/clone`,
      { phone_number_id: destinationPhoneNumberId })
  }

  unsubscribeContact ({ tenantId, phoneNumberId, contactId }: ContactPathParams, blocked: boolean, isBlacklisted: boolean) {
    return this.$axios.$patch(`/api/contacts/${tenantId}/${phoneNumberId}/${contactId}`, {
      is_blacklisted: isBlacklisted,
      blocked
    })
  }
}
